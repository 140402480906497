<script setup lang="ts">
import { getCurrentTime } from "../utils/time";
import { onBeforeMount, ref } from "vue";
const headerImg = "/header-bg.png";
const backIcon = "/backIocn.png";
const headerIcon = "/logo.svg";
const time = ref();
// console.log("runtimeConfig", runtimeConfig);

onBeforeMount(() => {
  const timeVal = getCurrentTime();
  time.value = timeVal;
});
const logo = "/logo.svg";
</script>
<template>
  <!-- 大屏特有的header -->
  <div class="w-full flex items-center div header h-16 fixed z-10">
    <img :src="headerImg" class="h-16 w-full">
    <div class="title flex justify-between items-center px-6 w-full absolute"
      style="top: 50%; transform: translateY(-50%)">
      <div class="flex justify-center items-center">
        <img :src="$config.public.site.logo" :class="$config.public.appName === 'guangjian' ? 'h-10' : 'h-6'">
        <div class="flex">
          <p class="ml-6 text-xl text-white">
            {{ $config.public.site.name }}
          </p>
          <p class="text-sm mt-2 ml-5 text-white opacity-80">
            {{ $config.public.site.enName }}
          </p>
        </div>
      </div>

      <div class="text-white flex items-center">
        <p v-if="time" class="text-base text-white">
          <span>{{ time.date }}</span>
          <span class="ml-5">{{ time.week }}</span>
        </p>
        <img :src="backIcon" class="w-4 h-4 ml-3 cursor-pointer" @click="$router.push('/pm/admin/project')">
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.header>img {
  position: absolute;
  top: 0;
  /* width: calc(100% - 6px); */
}

.title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
